












































import { Vue, Component } from "vue-property-decorator";
import { $transaction } from "@/services/TransactionService";
import { $overlay } from "@/services/OverlayService";

@Component
export default class Error extends Vue {
  get overlay() {
    return $overlay.show;
  }

  get transactionId() {
    return $transaction.getState().transaction?.id;
  }

  get redirectUrl() {
    return $transaction.getState().transaction?.redirect_url;
  }

  redirect() {
    if (this.redirectUrl) {
      window.location.href =
        this.redirectUrl + "?transaction_id=" + this.transactionId;
    }
  }

  async mounted() {
    $overlay.show = false;
    setTimeout(() => {
      this.redirect();
    }, 5000);
  }
}
